	<!--反馈列表-->
<template>
	<div class="ucLay radius">
	   <div class="ucCol_24 ucRow_24">
				  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #33cabb;">
						<div style="float: left;width: 55%;height: 100%;" >
						   <img src="../imgs/ind_iconBlk_01.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Usersl}}</dt>
						            <dd style="color: white;">用户数量</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
				  </div>
				  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #f96868;">
						<div style="float: left;width: 55%;height: 100%;" >
						    <img src="../imgs/ind_iconBlk_02.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Typesl}}</dt>
						            <dd style="color: white;">分类数量</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
				  </div>
				  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #15c377">
						<div style="float: left;width: 55%;height: 100%;" >
						    <img src="../imgs/ind_iconBlk_03.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Jxsl}}</dt>
						            <dd style="color: white;">绩效数量</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
				  </div>
				  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #926dde;">
						<div style="float: left;width: 55%;height: 100%;" >
						    <img src="../imgs/ind_iconBlk_04.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Jjje}}</dt>
						            <dd style="color: white;">奖金金额</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
				  </div>
			  	  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #465161;">
						<div style="float: left;width: 55%;height: 100%;" >
						    <img src="../imgs/ind_iconBlk_05.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Bxsl}}</dt>
						            <dd style="color: white;">报修数量</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
			  	  </div>
			  	  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #faa64b;">
						<div style="float: left;width: 55%;height: 100%;" >
						    <img src="../imgs/ind_iconBlk_06.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Fksl}}</dt>
						            <dd style="color: white;">反馈数量</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
			  	  </div>
			  	  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #f96197;">
						<div style="float: left;width: 55%;height: 100%;" >
						    <img src="../imgs/ind_iconBlk_07.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Xmsl}}</dt>
						            <dd style="color: white;">项目数量</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
			  	  </div>
			  	  <div class="ucCol_6 ucRow_4">
					<div class="bgGrayWhite" style="width: 320px;height: 120px;margin-left: 50px;margin-top:10px;border-radius:5px;background-color: #8d6658;">
						<div style="float: left;width: 55%;height: 100%;" >
						    <img src="../imgs/ind_iconBlk_08.png" style="position: relative;top: 20px; left: 27px;">
						</div>
						<div class="ucStatList"  style="float: left;width: 45%;height: 100%;">
						    <ul>   
						        <li style="margin-top: 26px;" >
						            <dt style="color: white;">{{this.Wlzl}}</dt>
						            <dd style="color: white;">物料种类</dd>
						        </li>
						    </ul>
						    <div class="clear"></div>
						</div>
					</div>
			  	  </div>
				<div class="ucCol_24 ucRow_2">
					<div style="padding-left: 50px; position: absolute; bottom: 0;">
					<el-form ref="searchForm"   label-width="240" :inline="true">
						 <el-form-item label="选择项目:">
							 <el-select v-model="projectId" placeholder="请选择" style="width:240px" clearable>
							   <el-option v-for="data in projectList" :key="data.plot_id" :label="data.name" :value="data.plot_id"></el-option>
							 </el-select>
						 </el-form-item>
						 <el-form-item label="选择分类:" prop="name">
							<el-select v-model="type" placeholder="请选择" style="width:240px" clearable>
							  <el-option v-for="data in typeList" :key="data.father_id" :label="data.name" :value="data.father_id"></el-option>
							</el-select>
						 </el-form-item>
						  
					  <el-form-item><el-button type="primary" @click="searchForm()">搜索</el-button></el-form-item>
						<!-- <el-form-item><el-button type="primary" @click="addMaterial()">新增</el-button></el-form-item> -->
					</el-form>
					</div>
				</div>
				<div class="ucCol_11 ucRow_16" style="padding-left: 50px;">
					<div class="ucCard" style="height: 90%;">
					        <div class="ucCard-header">
					            <div class="fl flName">报修数量</div>
					            <div class="fr frBtn">
					              <!-- <button type="submit" class="ucBtn ucBtn-edit ucBtn-blue ucBtn-icon" title="编辑">
					                       <i class="iImg"></i>
					               </button> -->
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content" style="height: 85%;" id="wvEcharts1"></div>
					 </div>
				</div>
				<div class="ucCol_11 ucRow_16" style="padding-left: 45px;">
					<div class="ucCard" style="height: 90%">
					        <div class="ucCard-header">
					            <div class="fl flName">分类统计</div>
					            <div class="fr frBtn">
					              <!-- <button type="submit" class="ucBtn ucBtn-edit ucBtn-blue ucBtn-icon" title="编辑">
					                       <i class="iImg"></i>
					               </button> -->
					            </div>
					            <div class="clear"></div>
					        </div>
					        <div class="ucCard-content"  style="height: 85%;"  id="wvEcharts2"></div>
					 </div>
				</div>
		</div>
	</div>
</template>
<script>
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";

import * as echarts from 'echarts';
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
	data() {
	  let self=this;
	  return {
		// ids:[],
	    dataList:[],  //表格数据集
	    reportInfoDialogVisible: false,
	    tableDataName:'',   //综合查询条件参数
	    optionsype:'',     //所属项目选项
	    project:'',       //选择的所属项目
		content:'',
		status:'',
		memo:'',
		create_time:'',
		update_time:'',
	    totalCount:0,          //总页数
	    pagesize:10,        //每页数量
	    currentPage:1,    //初始页
		projectId:"",
		projectList:[{id:1,name:"项目1"},{id:2,name:"项目2"}],
		type:"",
		typeList:[{id:1,name:"分类1"},{id:2,name:"分类2"}],
		Usersl:0,
		Typesl:0,
		Jxsl:0,
		Bxsl:0,
		Fksl:0,
		Xmsl:0,
		Jjje:0,
		Wlzl:0,
		option :{
				  title: {
					text: ''
				  },
				  tooltip: {
					trigger: 'axis'
				  },
				  legend: {
					data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
				  },
				  grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				  },
				  toolbox: {
					feature: {
					  saveAsImage: {}
					}
				  },
				  xAxis: {
					type: 'category',
					boundaryGap: false,
					data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				  },
				  yAxis: {
					type: 'value'
				  },
				  series: [
					{
					  name: '',
					  type: 'line',
					  stack: 'Total',
					  data: [120, 132, 101, 134, 90, 230, 210]
					}
				  ]
				},
				option2 :{
						  title: {
							text: ''
						  },
						  tooltip: {
							trigger: 'axis'
						  },
						  legend: {
							data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
						  },
						  grid: {
							left: '3%',
							right: '4%',
							bottom: '3%',
							containLabel: true
						  },
						  toolbox: {
							feature: {
							  saveAsImage: {}
							}
						  },
						  xAxis: {
							type: 'time',
							boundaryGap: false,
							data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
						  },
						  yAxis: {
							type: 'value'
						  },
						  series: [
							{
							  name: '',
							  type: 'line',
							  stack: 'Total',
							  data: [120, 132, 101, 134, 90, 230, 210]
							}
						  ]
						}
	  }
	},

  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
	this.getEcharts1();
	this.getEcharts2();
	this.getEchartsTypeList();
	this.getEchartsProList();
  },
  methods: {
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchDataList";
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.totalCount=res.datas.totalCount;
		this.Usersl=res.datas.Usersl;
		this.Typesl=res.datas.Typesl;
		this.Jxsl=res.datas.Jxsl;
		this.Bxsl=res.datas.Bxsl;
		this.Fksl=res.datas.Fksl;
		this.Xmsl=res.datas.Xmsl;
		this.Jjje=res.datas.Jjje;
		this.Wlzl=res.datas.Wlzl;
      }, null, this);
    },
    Modify: function(item) {
      this.user_name = item.user_name+"";
	  this.id = item.id;
	  this.project = item.project;
	  this.content = item.content;
	  this.status = item.status;
	  this.memo = item.memo;
      this.reportInfoDialogVisible = true;
    },
    closeReportInfo:function(){
      this.reportInfoDialogVisible = false;
      this.handleClickQuery();
    },
    //条件查询的方法
    queryMaterial:function(){
        this.dataList = [];
        var req = {};
        req.cmd = "searchReportList";
        req.start=(this.currentPage-1) * this.pagesize;
        req.count=this.pagesize;
        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
          console.log(res);
          this.dataList=res.datas.items;
          this.totalCount=res.datas.totalCount;
        }, null, this);
    },
	getEcharts1:function(){
        this.dataList = [];
        var req = {};
        req.cmd = "getEchartsData1";
		req.father_id = this.type;
		req.plot_id = this.projectId;
        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
	      var echarts_x=res.datas.echarts_x;
	      var echarts_y=res.datas.echarts_y;
		  this.myEcharts(echarts_y,echarts_x);
        }, null, this);
    },
	getEcharts2:function(){
	    this.dataList = [];
	    var req = {};
	    req.cmd = "getEchartsData2";
		req.plot_id = this.projectId;
	    this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {

		  var echarts_x=res.datas.echarts_x;
		  var echarts_yItems=res.datas.echarts_yItems;
		/*  var typeList=res.datas.typeList;
		  this.typeList = typeList; */
		  this.myEcharts2(echarts_yItems,echarts_x);
		  
	    }, null, this);
	},
	searchForm:function(){
		this.getEcharts1();
		this.getEcharts2();
	},
	getEchartsTypeList:function(){
	    this.dataList = [];
	    var req = {};
	    req.cmd = "getEchartsTypeList";
	    this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
		  this.typeList =res.datas.typeList;
	    }, null, this);
	},
	getEchartsProList:function(){
	    this.dataList = [];
	    var req = {};
	    req.cmd = "getEchartsProList";
	    this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
		  this.projectList = res.datas.projectList;
	    }, null, this);
	},
	Deleteinfo:function(item){
	  this.$confirm('是否确认删除?', '提示', {
	    confirmButtonText: '确定',
	    cancelButtonText: '取消',
	    type: 'warning'
	  }).then(() => {
	    this.deleteReport(item.id);
	  });
	},	
	deleteReport:function(id){
		var self = this;
		var req = {};
		req.id = id;
		req.cmd = "deleteReport"
		this.$sknet.postJson(this.$skconf("met.surl"), req, function(res){
		  self.$message("删除成功");
		  self.handleClickQuery();
		}, function(data) {
		}, self);
	},
    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
	myEcharts(series,Xseries){
		var items = this.echarts_ys;
		const myChart =echarts.init(document.getElementById('wvEcharts1'));
		var option = this.option;
		option.xAxis.data=Xseries;
		option.series[0].data = series;	
		option.legend.data=[];
		myChart.setOption(option);
	},
	myEcharts2(series,Xseries){
		var items = series;
		var series_y =[];
		var legendData =['Growth'];
		
		for(var i = 0;i<items.length;i++){
			var  seriesItem={};
			seriesItem.name = items[i].name;
			seriesItem.type ='line';
			//seriesItem.symbol = "none"
			seriesItem.data = items[i].echarts_y||[];
			series_y.push(seriesItem);
			legendData.push(items[i].name)
		}
		const myChart =echarts.init(document.getElementById('wvEcharts2'));
		
		var xAxis = {
			type: 'time',
			boundaryGap: false,
			}
		var option = this.option2;
		option.xAxis = xAxis;
		option.series = series_y;	
		option.legend.data=legendData;
		myChart.setOption(option);
	},
	//根据返回值判断
	formatter:function(row,colum){
	  const status={
	    0:'未处理',
	    1:'已处理',
	  }
	  return  status[row.status];
	},
  },


  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },


}


</script>